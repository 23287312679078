import '@ionic/core';
import {setupConfig} from '@ionic/core';
import env from "../../.env.json";
import 'swiper/swiper-element-bundle.js';
import "bit-virtual-scroll";
import {IEnv} from "../interfaces/env";
export const environment: IEnv = env;

if(environment.production){
  if(window){
    window.console.log=function(){};
  }
}

export default () => {
  setupConfig({mode: "md"});
};
